// src/layouts/RichText.js

import React from 'react'
import parse, { domToReact } from 'html-react-parser'
import PropTypes from 'prop-types'

function RichText({
  text,
  containerClassName = 'space-y-responsive-xl-24 px-responsive-xl-64',
}) {
  return (
    <section className="wysiwyg text scroll-trigger overflow-hidden">
      <div className={`container scroll-target ${containerClassName}`}>
        {parse(text, { replace: parserReplace })}
      </div>
    </section>
  )
}

// Function to handle <b> tags
function handleBoldTag(domNode, replaceFn) {
  return (
    <strong className="font-bold text-[#031E45]">
      {domToReact(domNode.children, { replace: replaceFn })}
    </strong>
  )
}

// Function to handle heading tags
function handleHeadingTag(domNode, replaceFn) {
  const Tag = domNode.name
  const props = processAttributes(domNode)
  return (
    <Tag {...props}>{domToReact(domNode.children, { replace: replaceFn })}</Tag>
  )
}

// Function to handle <a> tags
function handleAnchorTag(domNode, replaceFn) {
  const props = processAttributes(domNode, 'break-words')
  return (
    <a {...props}>{domToReact(domNode.children, { replace: replaceFn })}</a>
  )
}

// Main parser replace function
function parserReplace(domNode) {
  // Handle <b> tags
  if (domNode.type === 'tag' && domNode.name === 'b') {
    return handleBoldTag(domNode, parserReplace)
  }

  // Handle heading tags
  if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(domNode.name)) {
    return handleHeadingTag(domNode, parserReplace)
  }

  // Handle <a> tags
  if (domNode.type === 'tag' && domNode.name === 'a') {
    return handleAnchorTag(domNode, parserReplace)
  }

  // Return undefined to let `html-react-parser` use its default processing
  return undefined
}

// Utility function to handle attributes and add styles
function processAttributes(domNode, additionalClass = '') {
  const props = {
    ...domNode.attribs,
    className: `${additionalClass} ${domNode.attribs.class || ''}`.trim(), // Append additional class to existing classes
  }

  // Convert 'class' attribute to 'className' for React
  if (props.class) {
    props.className = `${additionalClass} ${props.class}`.trim()
    delete props.class
  }

  // Handle styles if they exist
  if (props.style) {
    props.style = parseStyle(props.style)
  }

  return props
}

// Helper function to convert style string to style object
function parseStyle(styleString) {
  return styleString.split(';').reduce((styleObj, styleProperty) => {
    let [key, value] = styleProperty.split(':')
    if (key && value) {
      key = key.trim()
      // value is trimmed;
      styleObj[key] = value
    }
    return styleObj
  }, {})
}

export default RichText

RichText.propTypes = {
  text: PropTypes.string,
}
